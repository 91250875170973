import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';


export default class VariableAddPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowVariableAddPopup: false,
            variableName: ''

        }
    }

    showVaribleAddPopup = () => {
        this.setState({
            isShowVariableAddPopup: true
        })
    }
    onAddClick = () => {
        if (this.state.variableName != '') {

            this.props.addVariable(this.state.variableName);
            this.setState({
                variableName: '',
                isShowVariableAddPopup: false
            })
        } else {

            this.setState({
                showError: this.state.variableName == '' ? true : false
            })
        }
    }

    onVariableNameChange = (event) => {

        this.setState({
            variableName: event.target.value,
            showError: event.target.value == '' ? true : false
        })

    }

    render() {
        return (
            <div className='p-grid sp-mt10 sp-mb5'>
                <div className="p-col-12">
                    <Button label="Add variable" className='sp-right'
                        onClick={this.showVaribleAddPopup} />
                </div>



                <Dialog
                    header={'Add Variable'}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='sp-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.isShowVariableAddPopup} style={{ maxWidth: '22vw', minWidth: '400px' }}
                    footer={() => {
                        return (<div>
                            <Button label="Cancel" className='p-button-outlined' onClick={() => { this.setState({ isShowVariableAddPopup: false, variableName: '' }) }} />
                            <Button label="Add" className='sp-m-lr10' onClick={this.onAddClick} />

                        </div>)
                    }}
                    onHide={() => this.setState({ isShowVariableAddPopup: false, variableName: '' })}>
                    <div>
                    </div>
                    <InputText placeholder="Enter variable" className="sp-w100p p-inputtext-style1"
                        value={this.state.variableName}

                        onChange={this.onVariableNameChange} />
                    {this.state.showError && <p className="p-error">Variable name is required</p>}
                </Dialog>
            </div>
        )
    }
}
