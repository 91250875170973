import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import { Chips } from 'primereact/chips';
import Service from 'src/Service';
import { v4 as uuidv4 } from 'uuid';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import templateFields from './composeEmail.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, isValidEmail, onChipsChange, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { bytesToSize, isValidFileSize, trimObj } from 'src/Utils';
import FileUpload from 'src/components/common/fileUpload';
import axios from 'axios';
import ServerlessService from 'src/Service/ServerlessService';
import { CLOUDFRONT_ENDPOINT, S3_PRESIGN_URL } from 'src/Service/config';
// let temp = {

//   to: ['shashi2puppy@gmail.com', 'shashidhar2katkam@gmail.com'],
//   cc: ['katkam494@gmail.com']
// }


class ComposeEmail extends Component {
  constructor(props) {
    super(props);
    this.formFields = getFormFields(templateFields, this.props.template ? this.props.template : {});
    this.state = {
      template: this.formFields.data,
      formValidations: this.formFields.formValidations,
      uploadedFilesInfo: this.props.template ? this.props.template.attachments || [] : []
    }
    this.service = new Service();
    this.serverlessService = new ServerlessService();
    this.fileUploadRef = React.createRef();
  }

  uploadFilesToS3 = async (files) => {
    let url = S3_PRESIGN_URL;
    let uploadedFiles = [];
    let thisObj = this;
    //  let files = e.target.files;
    for (let i = 0; i < files.length; i++) {



      if (!files[i].status) {


        let { data } = await axios.post(url, {
          "type": "insert",
          "key": thisObj.props.user.user.orgId + '/' + uuidv4() + files[i].name.replace(/\s/g, "")
        });
        let uploadStatus = await axios.put(data.data.presignedURL, files[i], {
          headers: {
            "Content-Type": "binary/octet-stream",
            "Access-Control-Allow-Origin": "*",
          }
        });
        uploadedFiles.push({
          path: CLOUDFRONT_ENDPOINT + data.data.contentStorageKey,
          name: files[i].name,
          type: files[i].type,
          size: files[i].size,
          status: uploadStatus.status == 200 ? true : false
        });
      } else {
        uploadedFiles.push(files[i]);
      }
    }

    return uploadedFiles;
    //  console.log(uploadedFiles, 'uploadedFiles')
  }


  isAdditionalValid = () => {

    let template = JSON.parse(JSON.stringify(this.state.template));
    let { formValidations } = this.state;

    let isValid = true;
    if (template.to && template.to.length) {

      if (template.to.some(a => !(isValidEmail(a)))) {
        isValid = false;
        formValidations.fields['to'] = { ...formValidations.fields['to'], isValid: false, errorMsg: `Invalid Email Addresses` }
      }

    }

    if (template.cc && template.cc.length) {

      if (template.cc.some(a => !(isValidEmail(a)))) {
        isValid = false;
        formValidations.fields['cc'] = { ...formValidations.fields['cc'], isValid: false, errorMsg: `Invalid CC Email Addresses` }
      }

    }
    if (template.bcc && template.bcc.length) {

      if (template.bcc.some(a => !(isValidEmail(a)))) {
        isValid = false;
        formValidations.fields['bcc'] = { ...formValidations.fields['bcc'], isValid: false, errorMsg: `Invalid BCC Email Addresses` }
      }

    }

    this.setState((prevState) => {
      return {
        formValidations
      };
    });

    return isValid

  }

  sendMessage = async () => {


    try {


      let isValid = true;
      let templateData = trimObj(this.state.template);
      const formStatus = isFormValid(templateFields, this.formFields.formValidations, templateData);

      if (!formStatus.formValidations.isFormValid) {
        this.setState({
          formValidations: formStatus.formValidations,
        });

        isValid = false
      }


      if (!this.isAdditionalValid()) {
        isValid = false;

      }



      if (isValid) {

        if (this.state.uploadedFilesInfo && this.state.uploadedFilesInfo.length) {
          if (this.state.uploadedFilesInfo.some(a => !isValidFileSize(a.size))) {
            this.toast.show({ severity: 'error', summary: 'Invalid Attachments', detail: 'Attachments can not be more than 20MB. Please remove', life: 3000 });
            return;
          } else {

          }

        }


        const url = `/email-bulk/send`;

        this.setState({
          isLoading: true,
        });

        let uploadedFiles = await this.uploadFilesToS3(this.state.uploadedFilesInfo);
        templateData.attachments = uploadedFiles || [];
        templateData.contactsType = 'individual';

        let fileUploadRef = this.fileUploadRef;


        this.service.post(url, {
          message: templateData,
          to: templateData.to
        }, true).then((res) => {

          if (res && res.status && res.res.status) {
            fileUploadRef.current.value = null;


            this.formFields = getFormFields(templateFields, this.props.template ? this.props.template : {});


            this.setState({
              isLoading: false,
              uploadedFilesInfo: [],
              template: this.formFields.data,
              formValidations: this.formFields.formValidations,
            });

            this.toast.show({ severity: 'success', summary: 'Mail Sent', detail: 'mail successfully submitted.', life: 3000 });
          } else {
            this.setState({
              isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
          }

        }).catch(e => {
          console.log(e);
          this.setState({
            isLoading: false
          });
          this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

      }
    } catch (e) {
      this.setState({
        isLoading: false
      })
      this.toast.show({ severity: 'error', summary: 'Some error occured.', detail: e.message, life: 3000 });
    }
  }

  componentDidMount() {
    if (this.props.isSessionChecked && !this.props.authenticated) {
      this.props.history.push('/login')
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
      this.props.history.push('/login')
    }

  }



  _removefile = (fileInf) => {
    let uploadedFiles = [];
    this.state.uploadedFilesInfo.forEach((fileInfo) => {
      if (fileInfo.name !== fileInf.name) {
        uploadedFiles = [...uploadedFiles, fileInfo];
      }
    });

    this.setState({
      uploadedFilesInfo: uploadedFiles
    });
  }

  showAttachment = (attachment) => {
    if (attachment.path) {
      window.open(attachment.path);
    } else {
      let src = URL.createObjectURL(attachment);
      window.open(src);
    }
  }

  filesUploadedBindingInfo = (filesInfo = []) => {
    let temp;
    temp = filesInfo.map((fileInfo) => {
      return <div key={fileInfo.name}>
        <div className="p-grid upload" dir="ltr">

          <div style={{ width: '100%', overflow: 'hidden' }}>
            <div style={{ width: '30px', float: 'right' }}>
              <span className="btn-remove-file sp-float-right"
                onClick={() => this._removefile(fileInfo)}><i className='pi pi-times' /></span>

            </div>
            <div style={{ marginRight: '30px' }} >
              <p onClick={() => { this.showAttachment(fileInfo) }} className={`filename ${isValidFileSize(fileInfo.size) ? 'valid-file' : "invalid-file"}`}>{fileInfo.name} <span className='sp-right'> {bytesToSize(fileInfo.size)}</span></p>
            </div>
          </div>


        </div>
      </div>
    });
    return temp;
  }

  _afterFilesUploaded = (files) => {
    for (let i = 0; i < files.length; i++) {
      this.setState((prevState, prevProps) => ({
        uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]]
      }));
    }
  }

  validateEmail = (e) => {




    return false;

  }



  render() {
    const { template, formValidations } = this.state;

    //  console.log(this.state, 'state-appointment')
    return (<>
      <Header>
        <div className='sp-main  sp-card sp-p20 sp-mt20'>
          <div>
            <div className="p-grid sp-mt20">

              <div className="p-col-4 p-md-2 p-lg-2">
                <p className='text-label'>To<span className='sp-required'>*</span></p>
              </div>
              <div className="p-col-8 p-md-10 p-lg-10 sp-mt2">
                {/* <InputText value={template.to}
                  className='sp-w100p p-inputtext-style1'
                  onChange={(e) => { onEmailChange(e.target.value, 'to', this, templateFields, template, formValidations, 'template', 'formValidations') }} /> */}
                <Chips
                  className='sp-w100p cc'
                  value={template.to}
                  addOnBlur={true}
                  //   onAdd={this.validateEmail}
                  onChange={(e) => onChipsChange(e.value, 'to', this, templateFields, template, formValidations, 'template', 'formValidations')}
                  separator=','
                ></Chips>

                {formValidations && !formValidations.fields['to'].isValid && <p className="p-error">{formValidations.fields['to'].errorMsg}</p>}
              </div>
            </div>

            <div className="p-grid">

              <div className="p-col-4 p-md-2 p-lg-2">
                <p className='text-label'>CC</p>
              </div>
              <div className="p-col-8 p-md-10 p-lg-10 sp-mt2">
                <Chips
                  className='sp-w100p cc'
                  addOnBlur={true}
                  value={template.cc}
                  onChange={(e) => onChipsChange(e.value, 'cc', this, templateFields, template, formValidations, 'template', 'formValidations')}
                  separator=','
                ></Chips>
                {formValidations && !formValidations.fields['cc'].isValid && <p className="p-error">{formValidations.fields['cc'].errorMsg}</p>}
              </div>
            </div>
            <div className="p-grid">

              <div className="p-col-4 p-md-2 p-lg-2">
                <p className='text-label'>BCC</p>
              </div>
              <div className="p-col-8 p-md-10 p-lg-10 sp-mt2">
                <Chips
                  className='sp-w100p cc'
                  addOnBlur={true}
                  value={template.bcc}
                  onChange={(e) => onChipsChange(e.value, 'bcc', this, templateFields, template, formValidations, 'template', 'formValidations')}
                  separator=','
                ></Chips>
                {formValidations && !formValidations.fields['bcc'].isValid && <p className="p-error">{formValidations.fields['bcc'].errorMsg}</p>}
              </div>
            </div>

            <div className="p-grid">

              <div className="p-col-4 p-md-2 p-lg-2">
                <p className='text-label'>Subject<span className='sp-required'>*</span></p>
              </div>
              <div className="p-col-8 p-md-10 p-lg-10 sp-mt2">
                <InputText value={template.subject}

                  className='sp-w100p p-inputtext-style1'
                  onChange={(e) => { onTextChange(e.target.value, 'subject', this, templateFields, template, formValidations, 'template', 'formValidations') }} />
                {formValidations && !formValidations.fields['subject'].isValid && <p className="p-error">{formValidations.fields['subject'].errorMsg}</p>}
              </div>
            </div>
            <div className="p-grid ">
              <div className="p-col-4 p-md-2 p-lg-2">
                <p className='text-label' >Body<span className='sp-required'>*</span></p>
              </div>
              <div className="p-col-8 p-md-10 p-lg-10 sp-mt2">
                <ClassicEditor
                  data={template.body}
                  classNamee="email-box"
                  onChange={(value) => { onTextChange(value, 'body', this, templateFields, template, formValidations, 'template', 'formValidations') }}
                />
                {formValidations && !formValidations.fields['body'].isValid && <p className="p-error">{formValidations.fields['body'].errorMsg}</p>}
              </div>
            </div>

            {/* <div dangerouslySetInnerHTML={{ __html: template.template }}></div> */}


            <div className="p-grid ">
              <div className="p-col-4 p-md-2 p-lg-2">
                <p className='text-label' >Attachments<span className='sp-required'>*</span></p>
              </div>
              <div className="p-col-8 p-md-10 p-lg-10 sp-mt2">

                <div className="attachment-wrapper" >
                  <div className='attachments'>
                    {this.filesUploadedBindingInfo(this.state.uploadedFilesInfo)}

                  </div>
                  <p className="sp-danger">{this.state.needHelpErr?.filesErr}</p>
                  <div className='sp-clearFix'></div>

                  <div className='file-upload-w'>
                    <FileUpload id="returnWindow"
                      multiple={true}
                      afterFilesUploaded={this._afterFilesUploaded}
                      inputRef={this.fileUploadRef}
                    />
                  </div>
                </div>
                <p className='upload-note sp-mt20'><span>Note:</span>Attachments can not be more than 20MB.</p>


              </div>
            </div>

            <Button label={`Send`} className='sp-m-lr10 sp-right'
              onClick={this.sendMessage}
            />

          </div>
        </div>
        <Toast ref={(el) => this.toast = el} position="bottom-right" />
        {this.state.isLoading && <LoadingComponent />}
      </Header>

    </>
    )
  }
}






const mapStateToProps = (state) => ({
  user: state.user,
  userType: state?.user?.userType,
  authenticated: state?.user?.authenticated,
  isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(ComposeEmail)));
