import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import { Button } from 'primereact/button';
import { trimObj } from 'src/Utils';
import doctorRegisterFormFields from './patientRegisterForm.json';
import Service from 'src/Service';
import { saveUserSession } from './../store/actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../session/index'
import LoadingComponent from 'src/LoadingComponent';
import ServerlessService from 'src/Service/ServerlessService';




class VerifyOTP extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowVerifyOtp: false,
            otp: null,
            otpErrMsg: null,
            otpResentCount: 0
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    onVerify = () => {
        let { otp } = this.state;

        if (otp && otp.length == 6) {
            this.setState({
                isLoading: true
            });
            const url = `/admin/verify`;

            this.serverlessService.post(url, { otp, _id: this.props.userId }, true).then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.saveUserSession(res.res);
                        this.props.history.push('/')
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })



        } else {
            this.setState({
                otpErrMsg: 'Invalid OTP.'
            })


        }
    }

    resendOTP = () => {
        if (this.state.otpResentCount < 3) {
            this.setState({
                isLoading: true
            });

            const url = `/admin/resend-otp`;
            this.service.post(url, { _id: this.props.userId }, true).then((res) => {
                if (res && res.status && res.res.status) {

                    this.setState((prevState) => {
                        return {
                            isLoading: false,
                            otpResentCount: prevState.otpResentCount + 1
                        };
                    });
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'OTP sent successfully.', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'OTP resent limit exceeded, please try later.', life: 3000 });
        }
    }


    render() {
        //  console.log(this.props)
        const { otp, otpErrMsg } = this.state;
        return (<>

            <div className='doctor-login-form'>
                <p>Please enter OTP recieved on mail</p>
                <div>
                    <InputText value={otp}
                        type="text"
                        min={6}
                        max={6}
                        maxLength={6}
                        minLength={6}
                        autoComplete="new-password"
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => {
                            this.setState({
                                otp: e.target.value
                            })
                        }} />
                    {otpErrMsg && <p className="p-error">{otpErrMsg}</p>}
                    <Toolbar className="sp-toolbar sp-mt20"
                        right={() => {
                            return (<Button label='Verify' className=''
                                onClick={this.onVerify}
                            />)
                        }}


                        left={() => {
                            return (<div>
                                <p>OTP not recieved? <span className='register-txt' onClick={this.resendOTP} >Resend</span></p>
                            </div>)
                        }}
                    ></Toolbar>
                </div>
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }

        </>)
    }
}


const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
});


export default connect(mapStateToProps, {
    saveUserSession,
})(Authentication(withRouter(VerifyOTP)));
