import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from 'src/Service';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import campaignFieldss from './campaign.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, onChipsChange, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { trimObj } from 'src/Utils';
import ServerlessService from 'src/Service/ServerlessService';
import { MultiSelect } from 'primereact/multiselect';
import _ from 'lodash';

let campaignFields = _.cloneDeep(campaignFieldss);


const IVR_VALUES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

class CreateCampaign extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(campaignFields, this.props.campaign ? this.props.campaign : {});
        this.state = {
            campaign: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    saveCampaign = () => {
        let campaignData = trimObj(this.state.campaign)
        const formStatus = isFormValid(campaignFields, this.formFields.formValidations, campaignData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            let url = `/voice-bulk/save`
            if (this.props.campaign) {
                url = `/voice-bulk/update`
            }


            this.setState({
                isLoading: true,
            });

            this.serverlessService.post(url, campaignData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.campaign ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }


    updateFormFields = (isRequired) => {
        campaignFields = campaignFields.map(field => {
            if (field.FieldName == 'ivrValues') {
                field.Required = isRequired;
            }
            return field;
        });

        this.formFields = getFormFields(campaignFields, this.state.campaign);


        this.setState({
            formValidations: this.formFields.formValidations
        })
    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.campaign ? 'Update' : 'Save'} Campaign`} className='sp-m-lr10'
                onClick={this.saveCampaign}
            />
        </div>)
    }


    render() {
        const { campaign, formValidations } = this.state;

        //   console.log(this.state, 'state-appointment')
        return (<>
            <Dialog header={`${this.props.campaign ? 'Edit' : 'Create'} Campaign`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '90%', height: '90vh' }}
                onHide={this.props.onHide}>

                <div>
                    <div className="p-grid sp-mt20">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Campaign Name<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={campaign.campaignName}
                                autoComplete="new-password"
                                className='sp-w200 p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'campaignName', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations') }} />
                            {formValidations && !formValidations.fields['campaignName'].isValid && <p className="p-error">{formValidations.fields['campaignName'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Campaign Id<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={campaign.campaignId}
                                autoComplete="new-password"
                                className='sp-w200 p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'campaignId', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations') }} />

                            {formValidations && !formValidations.fields['campaignId'].isValid && <p className="p-error">{formValidations.fields['campaignId'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Audio Length<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={campaign.audioLength}
                                autoComplete="new-password"
                                className='sp-w200 p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'audioLength', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations') }} />

                            {formValidations && !formValidations.fields['audioLength'].isValid && <p className="p-error">{formValidations.fields['audioLength'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Campaign Type<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <Dropdown value={campaign.campaignType}
                                options={[
                                    {
                                        label: 'Normal',
                                        value: 'normal'
                                    },
                                    {
                                        label: 'IVR',
                                        value: 'ivr'
                                    }
                                ]}
                                onChange={(e) => onTextChange(e.value, 'campaignType', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations', () => {
                                    this.updateFormFields(e.value == 'ivr')
                                })}
                                placeholder="Campaign Type" />
                            {formValidations && !formValidations.fields['campaignType'].isValid && <p className="p-error">{formValidations.fields['campaignType'].errorMsg}</p>}
                        </div>
                    </div>

                    {
                        campaign.campaignType == 'ivr' && <>
                            <div className="p-grid ">
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label' >IVR Values<span className='sp-required'>*</span></p>
                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <MultiSelect value={campaign.ivrValues}
                                        options={IVR_VALUES.map(a => {
                                            return {
                                                label: a,
                                                value: a
                                            }
                                        })}
                                        onChange={(e) => onChipsChange(e.value, 'ivrValues', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations')}
                                        placeholder="Campaign Type" />
                                    {formValidations && !formValidations.fields['ivrValues'].isValid && <p className="p-error">{formValidations.fields['ivrValues'].errorMsg}</p>}
                                </div>
                            </div>
                        </>
                    }
                    {/* <div dangerouslySetInnerHTML={{ __html: campaign.campaign }}></div> */}

                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateCampaign)));
