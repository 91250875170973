import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from '../../../Service';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import senderIdFields from './senderId.json';
import LoadingComponent from '../../../LoadingComponent';
import { Specialization } from '../../../Utils/constants';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from '../../../Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../../../session/index'
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from '../../../ClassicEditor';
import { trimObj } from '../../../Utils';
import ServerlessService from '../../../Service/ServerlessService';

class CreateSenderId extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(senderIdFields, this.props.editSenderId ? this.props.editSenderId : {});
        this.state = {
            senderId: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    saveSenderId = () => {
        let senderIdData = trimObj(this.state.senderId)
        const formStatus = isFormValid(senderIdFields, this.formFields.formValidations, senderIdData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            let url = `/headers/save`
            if (this.props.editSenderId) {
                url = `/headers/update`
            }


            this.setState({
                isLoading: true,
            });

            this.serverlessService.post(url, senderIdData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.editSenderId ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.editSenderId ? 'Update' : 'Save'} SenderId`} className='sp-m-lr10'
                onClick={this.saveSenderId}
            />
        </div>)
    }


    render() {
        const { senderId, formValidations } = this.state;

        //   console.log(this.state, 'state-appointment')
        return (<>
            <Dialog header={`${this.props.editSenderId ? 'Edit' : 'Add'} SenderID`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ minWidth: '450px', maxWidth: '50%' }}
                onHide={this.props.onHide}>

                <div>
                    <div className="p-grid sp-mt20">
                        <div className="p-col-4 p-md-4 p-lg-4">
                            <p className='text-label'>Sender ID<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={senderId.senderId}
                                autoComplete="new-password"
                                className='sp-w200 p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'senderId', this, senderIdFields, senderId, formValidations, 'senderId', 'formValidations') }} />
                            {formValidations && !formValidations.fields['senderId'].isValid && <p className="p-error">{formValidations.fields['senderId'].errorMsg}</p>}
                        </div>
                    </div>
                    <p className='upload-note sp-mt20'><span>Note:</span> Please enter sender Id as registered in DLT. Otherwise messages will not be delivered</p>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateSenderId)));
