import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import { Chips } from 'primereact/chips';
import Service from 'src/Service';
import reactStringReplace from 'react-string-replace';
import { v4 as uuidv4 } from 'uuid';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import smsFields from './composeSms.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, isValidEmail, isValidMobile, onChipsChange, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../../../session'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { bytesToSize, calcMessage, isValidFileSize, trimObj } from 'src/Utils';
import FileUpload from 'src/components/common/fileUpload';
import axios from 'axios';
import ServerlessService from 'src/Service/ServerlessService';
import { RadioButton } from 'primereact/radiobutton';
import SmsService from 'src/Service/SmsService';
import { CLOUDFRONT_ENDPOINT, S3_PRESIGN_URL } from 'src/Service/config';
// let temp = {


//   to: ['shashi2puppy@gmail.com', 'shashidhar2katkam@gmail.com'],
//   cc: ['katkam494@gmail.com']
// }


class ComposeEmail extends Component {
  constructor(props) {
    super(props);
    this.formFields = getFormFields(smsFields, this.props.sms ? this.props.sms : {});
    this.state = {
      sms: this.formFields.data,
      formValidations: this.formFields.formValidations,
      senderIds: [],
      templates: [],
      textMsgs: {},
      textMsgsErr: {},
      messageLength: null,
      credits: 0
    }
    this.smsService = new SmsService();
    this.serverlessService = new ServerlessService();
    this.fileUploadRef = React.createRef();
  }

  uploadFilesToS3 = async (files) => {
    let uploadedFiles = [];
    let thisObj = this;
    //  let files = e.target.files;
    for (let i = 0; i < files.length; i++) {



      if (!files[i].status) {


        let { data } = await axios.post(S3_PRESIGN_URL, {
          "type": "insert",
          "key": thisObj.props.user.user.orgId + '/' + uuidv4() + files[i].name.replace(/\s/g, "")
        });
        let uploadStatus = await axios.put(data.data.presignedURL, files[i], {
          headers: {
            "Content-Type": "binary/octet-stream",
            "Access-Control-Allow-Origin": "*",
          }
        });
        uploadedFiles.push({
          path: CLOUDFRONT_ENDPOINT + data.data.contentStorageKey,
          name: files[i].name,
          type: files[i].type,
          size: files[i].size,
          status: uploadStatus.status == 200 ? true : false
        });
      } else {
        uploadedFiles.push(files[i]);
      }
    }

    return uploadedFiles;
    //  console.log(uploadedFiles, 'uploadedFiles')
  }


  isAdditionalValid = () => {
    let sms = JSON.parse(JSON.stringify(this.state.sms));
    let { formValidations, textMsgs } = this.state;
    let isValid = true;
    if (sms.to && sms.to.length) {
      if (sms.to.some(a => !(isValidMobile(a)))) {
        isValid = false;
        formValidations.fields['to'] = { ...formValidations.fields['to'], isValid: false, errorMsg: `Invalid Mobile Numbers` }
      }
    }


    let textMsgsErr = {


    };


    if (textMsgs && Object.keys(textMsgs).length) {
      Object.keys(textMsgs).forEach(key => {
        if (!textMsgs[key] || textMsgs[key].trim() == '') {
          isValid = false;
          textMsgsErr[key] = true;
          formValidations.fields['message'] = { ...formValidations.fields['message'], isValid: false, errorMsg: `Please enter all place holders` }
        }
      });
    }


    this.setState((prevState) => {
      return {
        formValidations,
        textMsgsErr
      };
    });




    return isValid
  }

  addStr(str, index, stringToAdd) {
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }
  getSMSString = (str, txtEnteredMsgs) => {
    let textMsgs = {
    }
    var regex = /{#var#}/gi, result, indices = [];
    while ((result = regex.exec(str))) {
      textMsgs[result.index] = ''
    }
    textMsgs = { ...textMsgs, ...txtEnteredMsgs }
    Object.keys(textMsgs).forEach(a => {
      let result = str.lastIndexOf('{#var#}')
      str = str.slice(0, result) + str.slice(result + 7);
      str = this.addStr(str, result, textMsgs[`${result}`])
    });
    return str;

  }

  formTextMsgs = (str) => {
    let textMsgs = {
    }
    var regex = /{#var#}/gi, result, indices = [];
    while ((result = regex.exec(str))) {
      textMsgs[result.index] = ''
    }

    return textMsgs;
  }

  isSMSStringIsValid = () => {

  }

  onSubmit = async () => {


    try {


      let isValid = true;
      let smsData = trimObj(this.state.sms);
      const formStatus = isFormValid(smsFields, this.formFields.formValidations, smsData);

      if (!formStatus.formValidations.isFormValid) {
        this.setState({
          formValidations: formStatus.formValidations,
        });

        isValid = false
      }





      if (isValid) {
        smsData.message = this.getSMSString(smsData.message, this.state.textMsgs);
      }

      if (!this.isAdditionalValid()) {
        isValid = false;

      }
      // console.log(smsData, 'dddd')
      //      return


      if (isValid) {
        //alert('done')
        // return;
        const url = `/sms-bulk/send`;

        this.setState({
          isLoading: true,
        });

        smsData.contactsType = 'individual';



        this.smsService.post(url, {
          message: smsData,
          to: smsData.to
        }, true).then((res) => {

          if (res && res.status && res.res.status) {


            this.formFields = getFormFields(smsFields, this.props.sms ? this.props.sms : {});


            this.setState({
              isLoading: false,
              uploadedFilesInfo: [],
              sms: this.formFields.data,
              formValidations: this.formFields.formValidations,
              messageLength: 0,
              credits: 0
            });

            this.toast.show({ severity: 'success', summary: 'SMS Sent', detail: 'SMS successfully submitted.', life: 3000 });
          } else {
            this.setState({
              isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
          }

        }).catch(e => {
          console.log(e);
          this.setState({
            isLoading: false
          });
          this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

      }
    } catch (e) {
      this.setState({
        isLoading: false
      })
      this.toast.show({ severity: 'error', summary: 'Some error occured.', detail: e.message, life: 3000 });
    }
  }


  getFormData = () => {
    this.setState({
      isLoading: true,
    });
    const url = `/sms-templates/sms-form-data`;
    this.serverlessService.get(url, {}, true).then((res) => {

      if (res && res.status && res.res.status) {
        this.setState({
          isLoading: false,
          ...res.res.data,
        })

        // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Headers fetched successfully.', life: 3000 });
      } else {

        this.setState({
          isLoading: false
        });
        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
      }

    }).catch(e => {
      console.log(e);
      this.setState({
        isLoading: false
      });
      this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
    })


  }

  componentDidMount() {
    if (this.props.isSessionChecked && !this.props.authenticated) {
      this.props.history.push('/login')
    }



    this.getFormData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
      this.props.history.push('/login')
    }

  }



  _removefile = (fileInf) => {
    let uploadedFiles = [];
    this.state.uploadedFilesInfo.forEach((fileInfo) => {
      if (fileInfo.name !== fileInf.name) {
        uploadedFiles = [...uploadedFiles, fileInfo];
      }
    });

    this.setState({
      uploadedFilesInfo: uploadedFiles
    });
  }

  showAttachment = (attachment) => {
    if (attachment.path) {
      window.open(attachment.path);
    } else {
      let src = URL.createObjectURL(attachment);
      window.open(src);
    }
  }

  filesUploadedBindingInfo = (filesInfo = []) => {
    let temp;
    temp = filesInfo.map((fileInfo) => {
      return <div key={fileInfo.name}>
        <div className="p-grid upload" dir="ltr">

          <div style={{ width: '100%', overflow: 'hidden' }}>
            <div style={{ width: '30px', float: 'right' }}>
              <span className="btn-remove-file sp-float-right"
                onClick={() => this._removefile(fileInfo)}><i className='pi pi-times' /></span>

            </div>
            <div style={{ marginRight: '30px' }} >
              <p onClick={() => { this.showAttachment(fileInfo) }} className={`filename ${isValidFileSize(fileInfo.size) ? 'valid-file' : "invalid-file"}`}>{fileInfo.name} <span className='sp-right'> {bytesToSize(fileInfo.size)}</span></p>
            </div>
          </div>


        </div>
      </div>
    });
    return temp;
  }

  _afterFilesUploaded = (files) => {
    for (let i = 0; i < files.length; i++) {
      this.setState((prevState, prevProps) => ({
        uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]]
      }));
    }
  }

  validateEmail = (e) => {




    return false;

  }


  onChangeTemplate = (template_id) => {

    let _template = this.state.templates.find(a => a._id == template_id);


    let messageProps = calcMessage(_template.templateMessage);






    this.setState((prevState) => {
      return {
        templateId: _template.templateId,
        sms: {
          ...prevState.sms,
          templateId: _template.templateId,
          template_id: template_id,
          message: _template.templateMessage,
          templateMessage: _template.templateMessage,
          peId: _template.peId,
          language: messageProps.language,
          templateType: _template.templateType,
          senderId: null
        },
        senderIds: _template.senderIds,
        textMsgs: this.formTextMsgs(_template.templateMessage),
        messageLength: messageProps.length,
        credits: messageProps.credits
      };
    });

  }


  onTxtMsgFieldChange = (position, value) => {


    let textMsgs = {
      ...this.state.textMsgs,
      [position]: value
    }
    let message = this.getSMSString(this.state.sms.message, textMsgs);
    let messageProps = calcMessage(message);


    this.setState((prevState) => {
      return {
        textMsgs: {
          ...prevState.textMsgs,
          [position]: value
        },
        textMsgsErr: {
          ...prevState.textMsgs,
          [position]: value.length ? false : true
        },
        sms: {
          ...prevState.sms,
          language: messageProps.language,
        },
        messageLength: messageProps.length,
        credits: messageProps.credits
      }
    });
  }



  render() {
    const { sms, templates, textMsgs, textMsgsErr, formValidations, senderIds } = this.state;

    // console.log(this.state, 'state-appointment')
    return (<>
      <Header>
        <div className='sp-main sp-card sp-p20 sp-mt20'>
          <div>
            <div className="p-grid">
              <div className="p-col-12 p-md-2 p-lg-2">
                <p className='text-label'>Template </p>
              </div>
              <div className="p-col-12 p-md-10 p-lg-10 sp-mt2">
                <Dropdown value={sms.template_id}
                  options={templates}
                  filter={true}
                  optionLabel='templateName'
                  optionValue='_id'
                  onChange={(e) => this.onChangeTemplate(e.value)}

                  placeholder="Select Template" />
                {formValidations && !formValidations.fields['templateId'].isValid && <p className="p-error">{formValidations.fields['templateId'].errorMsg}</p>}
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-md-2 p-lg-2">
                <p className='text-label'>Sender Id</p>
              </div>
              <div className="p-col-12 p-md-10 p-lg-10 sp-mt2">
                <Dropdown value={sms.senderId}
                  options={senderIds}
                  onChange={(e) => onTextChange(e.value, 'senderId', this, smsFields, sms, formValidations, 'sms', 'formValidations')}
                  placeholder="Select Sender" />
                {formValidations && !formValidations.fields['senderId'].isValid && <p className="p-error">{formValidations.fields['senderId'].errorMsg}</p>}
              </div>
            </div>

            <div className="p-grid">
              <div className="p-col-12 p-md-2 p-lg-2">
                <p className='text-label'>Template Id </p>
              </div>
              <div className="p-col-12 p-md-10 p-lg-10 sp-mt2">
                <InputText value={sms.templateId}
                  disabled
                  className='sp-w100p  p-inputtext-style1'
                />
                {formValidations && !formValidations.fields['templateId'].isValid && <p className="p-error">{formValidations.fields['templateId'].errorMsg}</p>}
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-md-2 p-lg-2">
                <p className='text-label'>PE Id </p>
              </div>
              <div className="p-col-12 p-md-10 p-lg-10 sp-mt2">
                <InputText value={sms.peId}
                  disabled
                  className='sp-w100p  p-inputtext-style1'
                />
                {formValidations && !formValidations.fields['peId'].isValid && <p className="p-error">{formValidations.fields['peId'].errorMsg}</p>}
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-md-2 p-lg-2">
                <p className='text-label'>Template Type</p>
              </div>
              <div className="p-col-12 p-md-10 p-lg-10 sp-mt2">
                <RadioButton className="radio-inline" value='serviceimplicit' inputId="si"
                  name="templateType"
                  disabled
                  //onChange={(e) => onTextChange(e.value, 'templateType', this, smsFields, sms, formValidations, 'sms', 'formValidations')}
                  checked={sms.templateType === 'serviceimplicit'} />
                <label className='sp-label-s1 ' htmlFor="si">Service Implicit</label>
                <RadioButton className="radio-inline  sp-ml10" value='serviceexplicit' inputId="se"
                  name="templateType"
                  disabled
                  checked={sms.templateType === 'serviceexplicit'} />
                <label className='sp-label-s1' htmlFor="se">Service Explicit</label>
                <RadioButton className="radio-inline  sp-ml10" value='promotional' inputId="se"
                  name="templateType"
                  disabled
                  checked={sms.templateType === 'promotional'} />
                <label className='sp-label-s1' htmlFor="se">Promotional</label>
                {formValidations && !formValidations.fields['templateType'].isValid && <p className="p-error">{formValidations.fields['templateType'].errorMsg}</p>}
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-md-2 p-lg-2">
                <p className='text-label'>SMS Language</p>
              </div>
              <div className="p-col-12 p-md-10 p-lg-10 sp-mt2">
                <RadioButton className="radio-inline" value='english' inputId="interanl"
                  name="language"
                  disabled
                  // onChange={(e) => onTextChange(e.value, 'language', this, smsFields, sms, formValidations, 'sms', 'formValidations')}
                  checked={sms.language === 'english'} />
                <label className='sp-label-s1' htmlFor="interanl">English</label>
                <RadioButton className="radio-inline sp-ml10"
                  value='others'
                  inputId="non-interanl" name="language"
                  disabled
                  // onChange={(e) => onTextChange(e.value, 'language', this, smsFields, sms, formValidations, 'sms', 'formValidations')}
                  checked={sms.language === 'others'} />
                <label className='sp-label-s1' htmlFor="non-interanl">Others</label>
                {formValidations && !formValidations.fields['language'].isValid && <p className="p-error">{formValidations.fields['language'].errorMsg}</p>}
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-md-2 p-lg-2">
                <p className='text-label'>Message</p>
              </div>
              <div className="p-col-12 p-md-10 p-lg-10 sp-mt2">
                <div className='sms-template-txt'>
                  {reactStringReplace(sms.message, '{#var#}', (match, i, offset) => (
                    <InputText value={textMsgs[offset] ? textMsgs[offset] : ''} onChange={(e) => { this.onTxtMsgFieldChange(offset, e.target.value) }} className={`p-inputtext-style1 ${textMsgsErr[offset] ? 'p-invalid' : ''}`} />
                  ))}
                </div>
                {/* <InputTextarea value={sms.message}
                  rows={6}
                  disabled={!sms.templateId}
                  onChange={(e) => { onTextChange(e.target.value, 'message', this, smsFields, sms, formValidations, 'sms', 'formValidations') }}
                  className='sp-w100p  p-inputtext-style1'
                /> */}
                {formValidations && !formValidations.fields['message'].isValid && <p className="p-error">{formValidations.fields['message'].errorMsg}</p>}
                <span className='sp-note'>SMS Credits: {this.state.credits}</span> <span className='sp-note sp-ml10'>SMS Length: {this.state.messageLength}</span>
              </div>
            </div>

            <div className="p-grid sp-mt20">
              <div className="p-col-12 p-md-2 p-lg-2">
                <p className='text-label'>Phone Numbers<span className='sp-required'>*</span></p>
              </div>
              <div className="p-col-12 p-md-10 p-lg-10 sp-mt2">
                <Chips
                  className='sp-w100p cc'
                  value={sms.to}
                  addOnBlur={true}
                  onChange={(e) => onChipsChange(e.value, 'to', this, smsFields, sms, formValidations, 'sms', 'formValidations')}
                  separator=','
                ></Chips>

                {formValidations && !formValidations.fields['to'].isValid && <p className="p-error">{formValidations.fields['to'].errorMsg}</p>}
              </div>
            </div>
            <Button label={`Send`} className='sp-m-lr10 sp-right'
              onClick={this.onSubmit}
            />

          </div>
        </div>
        <Toast ref={(el) => this.toast = el} position="bottom-right" />
        {this.state.isLoading && <LoadingComponent />}
      </Header>

    </>
    )
  }
}






const mapStateToProps = (state) => ({
  user: state.user,
  userType: state?.user?.userType,
  authenticated: state?.user?.authenticated,
  isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(ComposeEmail)));
