import XLSX from 'xlsx';
import axios from 'axios';

export const trimObj = (obj) => {
    if (obj != null && !Array.isArray(obj) && typeof obj != 'object') return obj;
    return Object.keys(obj).reduce(function (acc, key) {
        acc[key.trim()] = typeof obj[key] == 'string' ? obj[key].trim() : (obj[key] == null || obj[key] == undefined) ? obj[key] : trimObj(obj[key]);
        return acc;
    }, Array.isArray(obj) ? [] : {});
}

export const warningDailogInit = {
    visible: false,
    headerMsg: '',
    message: ''
}



export const getTimeSlotByKey = (key) => {

    switch (key) {
        case 1000:
            return '10:00 AM'
        case 1030:
            return '10:30 AM'
        case 1100:
            return '11:00 AM'
        case 1130:
            return '11:30 AM'
        case 1200:
            return '12:00 PM'
        case 1230:
            return '12:30 PM'
        case 1400:
            return '02:00 PM'
        case 1430:
            return '02:30 PM'
        case 1500:
            return '03:00 PM'
        case 1530:
            return '03:30 PM'
        case 1600:
            return '04:00 PM'
        case 1630:
            return '04:30 PM'
        case 1800:
            return '06:00 PM'
        case 1830:
            return '06:30 PM'
        case 1900:
            return '07:00 PM'
        case 1930:
            return '07:30 PM'

    }

}



export const bytesToSize = (bytes) => {

    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}


export const isValidFileSize = (bytes) => {
    return bytes <= 20971520

}


export const getCredits = (length = 0, language) => {
    if (language == 'english') {
        if (length <= 160) {
            return 1;
        } else {
            return Math.ceil(length / 153)
        }
    } else {
        if (length <= 70) {
            return 1;
        } else {
            return Math.ceil(length / 67)
        }
    }
}


export const calcSegment = (messageBodyText) => {
    if (window.SegmentedMessage) {
        const segmentedMessage = new window.SegmentedMessage(messageBodyText, 'auto');

        segmentedMessage.language = segmentedMessage.encodingName == 'GSM-7' ? 'english' : 'others'
        segmentedMessage.messageLength = segmentedMessage.numberOfUnicodeScalars
        return segmentedMessage;
    } else {
        throw new Error();

    }
}


var gsm = `@ 
£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞ^{}\[~]|€ÆæßÉ!\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà\r\n`;

export const calcMessage = (message = '') => {
    let isGSM7 = message.split('').every(a => gsm.indexOf(a) !== -1);

    return {
        language: isGSM7 ? 'english' : 'others',
        length: message.length,
        credits: getCredits(message.length, isGSM7 ? 'english' : 'others'),
        data_coding: isGSM7 ? 0 : 8
    }
}


export const onCopyClick = (text) => {
    navigator.clipboard.writeText(text);
}


function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

export function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}


export const exportJSONToCSV = (data, filename) => {
    let headers = [];
    Object.keys(data[0]).forEach(k => {
        headers.push(k);
    })
    exportCSVFile(headers, data, filename)
}



export const handleDownload = (url, filenameWithExtension) => {


    // this.setState({
    //     isLoading: true
    // })
    // axios.get(url, {
    //     responseType: 'blob',
    // })
    //     .then((res) => {

    //         const url = window.URL.createObjectURL(
    //             new Blob([res.data]),
    //         );

    //         // const extension = name.slice(name.lastIndexOf('.')).split('.')[1];
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute(
    //             'download',
    //             filenameWithExtension
    //         );

    //         // Append to html link element page
    //         document.body.appendChild(link);

    //         // Start download
    //         link.click();

    //         // Clean up and remove the link
    //         link.parentNode.removeChild(link);


    //         this.setState({
    //             isLoading: false
    //         })

    //         // fileDownload(res.data, filename)
    //     }).catch(e => {


    //     })
}

export function getStringsBetweenChars(inputString, startChar, endChar) {
    let stringsBetween = [];
    let startIndex = inputString.indexOf(startChar);
    let endIndex = inputString.indexOf(endChar, startIndex + 1);

    while (startIndex !== -1 && endIndex !== -1) {
        let stringBetween = inputString.substring(startIndex + 2, endIndex);
        stringsBetween.push(stringBetween);
        startIndex = inputString.indexOf(startChar, endIndex + 1);
        endIndex = inputString.indexOf(endChar, startIndex + 1);
    }

    return stringsBetween;
}

export function getVariables(string) {
    return getStringsBetweenChars(string, '{{', '}}')
}

const getRegex = (txt) => {
    return new RegExp(`${txt}`, 'g');
}

export const generateEmailTextWithVariables = (msg, mapFields, user) => {
    Object.keys(mapFields).forEach(key => {
        msg = msg.replace(getRegex(`{{${key}}}`), user[mapFields[key]]);
    });
    return msg;
}



/* list of supported file types */
export const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
export const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};




export const handleDownloadFromUrl = (url, filenameWithExtension) => {

    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            const url = window.URL.createObjectURL(
                new Blob([res.data]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                filenameWithExtension
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        }).catch(e => {

        })
}