import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import Service from 'src/Service';
import cloneDeep from 'lodash/cloneDeep';
import { Dialog } from 'primereact/dialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import { BasicLazyParams } from 'src/Utils/constants';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';
import { getTimeSlotByKey, warningDailogInit } from 'src/Utils';
import { Specialization } from 'src/Utils/constants';
// import AppointmentInfo from './AppointmentInfo';
import SendEmail from './_sendEmail';
import Header from './../Header';
import UserView from './userView';
import BulkUploadDetails from 'src/EmailUsers/bulkUpload/bulkUploadDetails';
import BulkEmailTransactionDetails from './bulkEmailTransactionDetails';
import LoadingComponent from 'src/LoadingComponent';
import Authorizer, { PERMISSIONS } from './../session/authorize';
import ServerlessService from 'src/Service/ServerlessService';

class EmailUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAddUser: false,
            appointments: [],
            lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: -1 },
            globalSearch: '',
            isShowUser: false,
            appointment: null,
            currentFilter: 1,
            warningDialog: warningDailogInit,
            tags: [],
            selectedTags: [],
            currentStep: 0,
            transactionId: null
        }

        this.serverlessService = new ServerlessService();
    }


    showBookAnAppointment = () => {
        this.setState({ isShowAddUser: true, editUser: false, isShowAllDetails: false, });
        // this.props.history.push('/appointment-book')

    }


    onChangeStatusType = (currentFilter) => {
        this.setState((prevState) => {
            return {

                currentFilter: currentFilter,
                lazyParams: BasicLazyParams
            }
        }, this.getAppointments);
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getAppointments);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getAppointments);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getAppointments);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getAppointments);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }



    getAppointments = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            appointments: []
        });
        const url = `/email-bulk/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.post(url, { tags: this.state.selectedTags }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    appointments: res.res.data,
                    totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Users fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }

    getTags = () => {

        this.setState({
            isLoading: true,
            appointments: []
        });
        const url = `/emailusers-tags/all`;
        this.serverlessService.post(url, { status: this.state.selectedTags }, true).then((res) => {

            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    tags: res.res.data,
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }



    showUser = (user) => {

        this.setState({
            isShowUser: true,
            user: user
        })
    }


    editUser = (user) => {
        this.setState({
            isShowAddUser: true,
            editUser: user
        })
    }

    deleteUser = (rowData) => {
        let data = { ...rowData }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You want delete the user, it can't be undone once deleted`
            },
            editUser: data
        });

    }

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });

        const url = `/user/delete`;
        this.serverlessService.post(url, { _id: this.state.editUser._id }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    editUser: null,
                    isLoading: false
                }, () => {
                    this.getAppointments();
                })


                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Users deleted successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }



    actionsUser = (rowData) => {
        return (<>
            <div className="p-d-flex sp-mlr10 user-edit-t">
                {/* <span data-pr-tooltip="View User" data-pr-position="bottom" className={`sp-ml10 anal${rowData._id.substr(2)}`} onClick={() => this.showUser(rowData)} >
                    <i className='pi pi-eye' />
                </span>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.anal${rowData._id.substr(2)}`} /> */}
                {/* <Authorizer permId={PERMISSIONS.EMAIL_COMPAIGN_GENERAL_SEARCH} >
                    <span data-pr-tooltip="Edit compaign" data-pr-position="bottom" className={`sp-ml10 edit${rowData._id.substr(2)}`} onClick={() => { this.editUser(rowData) }}>
                        <i className='pi pi-pencil' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id.substr(2)}`} />
                </Authorizer>
                <Authorizer permId={PERMISSIONS.EMAIL_COMPAIGN_GENERAL_SEARCH} >
                    <span data-pr-tooltip="Delete compaign" data-pr-position="bottom" className={`sp-ml10 del${rowData._id.substr(2)}`} onClick={() => { this.deleteUser(rowData) }}>
                        <i className='pi pi-trash' />
                    </span>
                </Authorizer>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.del${rowData._id.substr(2)}`} /> */}
            </div>
        </>
        );
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    onSaveUser = (transactionId) => {

        this.toast.show({ severity: 'success', summary: `Campaign created Succesfully. Please check the compaign with reference number ${transactionId}`, life: 20000 });
        this.setState({
            isShowAddUser: false,
            lazyParams: BasicLazyParams
        }, () => {
            this.getAppointments();
        })

    }


    componentDidMount() {


        this.getAppointments();
        // this.getTags();
    }


    getTransactionDetails = (transactionId) => {
        this.setState({
            transactionId,
            isShowAllDetails: true
        });

    };

    render() {
        const { currentFilter, currentStep } = this.state;

        // console.log(this.state.appointments)
        const header = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >All Campaigns</h2>
                    {/* 
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 1 ? 'p-button-outlined' : ''}`} label={'Booked'} onClick={() => this.onChangeStatusType(1)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 2 ? 'p-button-outlined' : ''}`} label={'Visisted'} onClick={() => this.onChangeStatusType(2)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 3 ? 'p-button-outlined' : ''}`} label={'Cancelled'} onClick={() => this.onChangeStatusType(3)} /> */}

                    {this.state.showSearch && <InputText placeholder="Search" className="sp-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />}
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        <Authorizer permId={PERMISSIONS.EMAIL_COMPAIGN_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search sp-tbl-icon sp-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".sp-tbl-srch" />
                        </Authorizer>
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );


        return (<div>
            <Header>
                <div className='sp-main'>






                    {
                        this.state.isShowAddUser ? <SendEmail
                            editUser={this.state.editUser} onHide={() => {

                                this.setState({
                                    isShowAddUser: false
                                })
                            }}

                            onSave={this.onSaveUser}

                        /> : <>


                            <Toolbar className="sp-toolbar"
                                right={() => {
                                    return (
                                        <Authorizer permId={PERMISSIONS.EMAIL_COMPAIGN_REFRESH} >
                                            <Button label="Refresh" className='sp-mlr30'
                                                icon="pi pi-refresh"
                                                disabled={this.state.isLoading}
                                                onClick={() => {
                                                    this.getAppointments()
                                                }} />
                                        </Authorizer>
                                    )
                                }}

                                left={() => {
                                    return <Authorizer permId={PERMISSIONS.EMAIL_COMPAIGN_CREATE_COMPAIGN} > <Button label="+ Create Campaign" className='sp-m-lr10'
                                        onClick={this.showBookAnAppointment} />
                                    </Authorizer>
                                }}


                            ></Toolbar>
                            <Authorizer permId={PERMISSIONS.EMAIL_COMPAIGN_LIST} >
                                <div className="card datatable-crud-demo sp-m30 fetch-q-tabled">
                                    {/* <LazyTable ></LazyTable> */}
                                    <DataTable ref={(el) => this.dt = el}
                                        //  lazy
                                        scrollable
                                        responsiveLayout="scroll"
                                        //  autoLayout={true}
                                        rowHover
                                        value={this.state.appointments}
                                        // selection={this.state.selectedProducts}
                                        // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                        dataKey="_id"
                                        //</div>={true}
                                        lazy
                                        onSort={this.onSort}
                                        onPage={this.onPage}
                                        onFilter={this.onFilter}
                                        first={this.state.lazyParams.first}
                                        last={this.state.totalRecords}
                                        rows={this.state.lazyParams.rows}
                                        totalRecords={this.state.totalRecords}
                                        paginator
                                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                                        paginatorUser="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportUser="Showing {first} to {last} of {totalRecords} products"
                                        globalFilter={this.state.globalFilter}
                                        header={header}

                                    >
                                        <Column field="transactionId" header="Transaction Id" />
                                        <Column field="bulkStatus" header="Status" />
                                        <Column field="bulkStatusMessage" header="Status Message" />
                                        <Column field="initiatedBy" header="Initiated By" body={(rowData) => {
                                            return (<>
                                                {rowData.initiatedBy.name}
                                            </>)
                                        }} />
                                        <Column field="createdAt" header="Date" body={(rowData) => {
                                            return (<>
                                                <span>{moment(rowData.createdAt).format('LL')}</span>
                                            </>)
                                        }} />
                                        <Column header='Actions' body={(rowData) => {
                                            return (<>
                                                <Authorizer permId={PERMISSIONS.EMAIL_COMPAIGN_SHOW_DETAILS} >
                                                    <Button label="Show Details" onClick={() => this.getTransactionDetails(rowData._id)} className='p-button-outlined' />
                                                </Authorizer>
                                            </>
                                            );
                                        }} ></Column>
                                        {/* <Column
                                        body={this.actionsUser} header="Actions" ></Column> */}
                                    </DataTable>

                                </div>
                            </Authorizer>
                        </>
                    }


                    {
                        this.state.isShowAllDetails && <BulkEmailTransactionDetails transactionId={this.state.transactionId} />
                    }
                    {/* {this.state.transactionDetails && this.state.transactionDetails.length ? (
                        <BulkUploadDetails transactionId={this.state.transactionId} data={this.state.transactionDetails} />
                    ) : null} */}


                    {this.state.isLoading && <LoadingComponent />}
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </div>
            </Header>
        </div>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(EmailUsers)));
