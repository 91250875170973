import React, { Component } from 'react'

export default class PreviewEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {


        }
    }

    showAttachment = (attachment) => {
        if (attachment.path) {
            window.open(attachment.path);
        } else {
            let src = URL.createObjectURL(attachment);
            window.open(src);
        }
    }

    filesUploadedBindingInfo = (filesInfo = []) => {
        let temp;
        temp = filesInfo.map((fileInfo) => {
            return <div key={fileInfo.name}>
                <div className="p-grid upload" dir="ltr">
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <p onClick={() => { this.showAttachment(fileInfo) }} className={`filename`}>{fileInfo.name} </p>
                    </div>
                </div>
            </div>
        });
        return temp;
    }


    render() {
        const { message } = this.props;

        return (<div className='p-card sp-p20 email-preview' style={{}}>
            <h3>Preview Email</h3>
            <p><b>Note:</b> This preview here is showing for the first contact from the list.</p>
            <hr />
            {/* <p ><span className='title'>From:</span>{message?.from}</p> */}
            <p><span className='title'>To:</span>{message?.to}</p>
            <p><span className='title'>Subject:</span>{message?.subject}</p>
            <p><span className='title'>Body:</span></p>
            <div className="body-msg" dangerouslySetInnerHTML={{ __html: message?.body || '' }}></div>
            <p><span className='title'>Attachments</span></p>
            <div className="body-msg" >
                {message?.attachments && this.filesUploadedBindingInfo(message?.attachments)}
            </div>
        </div >
        )
    }
}
