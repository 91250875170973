import React, { Component } from 'react';
import { Menubar } from 'primereact/menubar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { socket } from "./../App";
import { RadioButton } from 'primereact/radiobutton';
import Authentication from '../session/index'
import ServerlessService from './../Service/ServerlessService';
import { TieredMenu } from 'primereact/tieredmenu';
import LoadingComponent from 'src/LoadingComponent';
import './styles.scss'
import { Toast } from 'primereact/toast';


const getThemes = () => {
    if (localStorage.getItem('themes')) {
        return JSON.parse(localStorage.getItem('themes'));
    } else {
        return [
            {
                name: "Light Blue",
                value: null,
                isSelected: true
            },
            {
                name: "Pink",
                value: 'pink',
                isSelected: false
            },
            {
                name: "Green",
                value: 'green',
                isSelected: false
            },
            {
                name: "Yellow",
                value: 'yellow',
                isSelected: false
            },
        ]
    }
}


class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            themes: getThemes(),
            isShowChangeRole: false,
            total: 0,
            credits: null
        }

        this.serverlessService = new ServerlessService();
    }




    onLogout() {
        localStorage.clear();
        this.props.history.push('/')
        window.location.reload();

    }



    getNavbarModules = () => {
        let ModuleMenuItems = [{
            label: 'Dashboard',
            className: `${(window.location.pathname == '/') ? 'sp-menu-active' : 'ddd'}`,
            command: () => {
                this.props.history.push('/')
            },
        },
        // {
        //     label: 'Appointments',
        //     className: `${(window.location.pathname == '/appointments') ? 'sp-menu-active' : 'ddd'}`,
        //     command: () => {
        //         this.props.history.push('/appointments')
        //     },
        // },
        {
            label: 'Admin',
            className: `${(window.location.pathname == '/admin') ? 'sp-menu-active' : 'ddd'}`,

            items: [

                {
                    label: 'Admin Management',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    // isPermRequired: true,
                    // uId: '0761fb3e-2f31-45e5-a431-c3d2a745d1a3',
                    items: [

                        {
                            label: 'Admin Users',
                            // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                            command: () => {
                                this.props.history.push('/admin')
                            },
                            isPermRequired: true,
                            uId: '0761fb3e-2f31-45e5-a431-c3d2a745d1a3'
                            // icon: 'pi pi-fw pi-align-left'
                        }
                        ,
                        {
                            label: 'Admin Roles',
                            // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                            command: () => {
                                this.props.history.push('/roles')
                            },
                            isPermRequired: true,
                            uId: 'c54bee0c-bf05-4739-b88c-eff7a1850008'
                            // icon: 'pi pi-fw pi-align-left'
                        }]
                    //isPermRequired: true,
                    //uId: '8661adca-b5c5-49e0-abe1-67ea1f6839c4',
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Configuration',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/config')
                    },
                    isPermRequired: true,
                    uId: 'a7ee7332-791a-4fd5-b457-1226b40186b3',
                    // icon: 'pi pi-fw pi-align-left'
                }
            ]
        },

        {
            label: 'Email Services',
            className: `${(window.location.pathname == '/signature' || window.location.pathname == '/template') ? 'sp-menu-active' : 'ddd'}`,
            // command: () => { this.props.history.push('/organization') },
            //command: () => { console.log('abcdef'); },
            //url: '/home'
            //  isPermRequired: false,

            items: [

                {
                    label: 'Email Campaign',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/bulk-emails')
                    },
                    isPermRequired: true,
                    uId: '6fa2e1dc-b104-410e-ab7b-e471c8b89334'
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Compose Email',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/compose')
                    },
                    isPermRequired: true,
                    uId: 'ffd94731-f615-4101-828a-70bbd6022651'
                    // icon: 'pi pi-fw pi-align-left'
                },

                {
                    label: 'Email Signatures',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/signature')
                    },
                    isPermRequired: true,
                    uId: '7d934554-70c2-4658-99af-143fff1ea1a0'
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Email Templates',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/template')
                    },
                    isPermRequired: true,
                    uId: '7f7086de-6c86-4855-985a-a25d0bbc5527',
                    // icon: 'pi pi-fw pi-align-left'
                },
                // {
                //     label: 'Email Users',
                //     // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                //     command: () => {
                //         this.props.history.push('/emailusers')
                //     },
                //     isPermRequired: true,
                //     uId: 'f30eb241-f05f-4700-bde9-90c7c22d5e96',
                //     // icon: 'pi pi-fw pi-align-left'
                // },
                // {
                //     label: 'Bulk Email Users Upload',
                //     // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                //     command: () => {
                //         this.props.history.push('/bulk-emailusers')
                //     },
                //     isPermRequired: true,
                //     uId: '6288619d-c523-4b4b-aec7-291827e2179a',
                //     // icon: 'pi pi-fw pi-align-left'
                // },

            ]

        },
        {
            label: 'SMS Services',
            //className: `${(window.location.pathname == '/sms' || window.location.pathname == '/template') ? 'sp-menu-active' : 'ddd'}`,
            // command: () => { this.props.history.push('/organization') },
            //command: () => { console.log('abcdef'); },
            //url: '/home'
            //  isPermRequired: false,

            items: [
                {
                    label: 'SMS Campaign',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/bulk-sms')
                    },
                    isPermRequired: true,
                    uId: '565478d0-9bb6-45f2-916a-fad0bd4d5139'
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Send SMS',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/send-sms')
                    },
                    isPermRequired: true,
                    uId: '66225067-2fd9-49cf-a461-feb592b593b3'
                    // icon: 'pi pi-fw pi-align-left'
                },
                // {
                //     label: 'PE',
                //     // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                //     command: () => {
                //         this.props.history.push('/pe-management')
                //     },
                //     isPermRequired: true,
                //     uId: '66225067-2fd9-49cf-a461-feb592b593b3'
                //     // icon: 'pi pi-fw pi-align-left'
                // },
                {
                    label: 'Principal Entities',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/principal-entities')
                    },
                    isPermRequired: true,
                    uId: '66225067-2fd9-49cf-a461-feb592b593b3'
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'SMS Sender IDs',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/sms-senderids')
                    },
                    isPermRequired: true,
                    uId: '768c1e54-4276-4278-9570-393d8ec696c3'
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'SMS Templates',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/sms-templates')
                    },
                    isPermRequired: true,
                    uId: '09fc1f19-e490-4368-9908-8bc3e3a8bd71'
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'SMS Reports',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/sms-reports')
                    },
                    isPermRequired: true,
                    uId: '01862a0d-2593-47f9-a064-2eecaca34487'
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'API Documentation',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/api-doc')
                    },
                    isPermRequired: true,
                    uId: '6a063b26-e589-4d42-9cb3-c5cd7584159f'
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    separator: true
                },
                {
                    label: 'URL Shortener',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/url-shortener')
                    },
                    isPermRequired: true,
                    uId: '8cef7027-288e-4ff6-b91c-5ea29334a2b5'
                    // icon: 'pi pi-fw pi-align-left'
                },
            ]

        },
        {
            label: 'Voice Services',
            items: [
                {
                    label: 'Voice Campaign',
                    command: () => {
                        this.props.history.push('/bulk-voice')
                    },
                    //  isPermRequired: true,
                    uId: '565478d0-9bb6-45f2-2345-fad0bd4d5139'
                },
            ]
        },
            // {
            //     label: 'Consultants',
            //     className: `${(window.location.pathname == '/consultant-forms' || window.location.pathname == '/template') ? 'sp-menu-active' : 'ddd'}`,
            //     // command: () => { this.props.history.push('/organization') },
            //     //command: () => { console.log('abcdef'); },
            //     //url: '/home'
            //     //  isPermRequired: false,

            //     items: [

            //         {
            //             label: 'Forms Management',
            //             // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
            //             command: () => {
            //                 this.props.history.push('/consultant-forms')
            //             },
            //             //  isPermRequired: true,
            //             // uId: '6fa2e1dc-b104-410e-ab7b-e471c8b89334'
            //             // icon: 'pi pi-fw pi-align-left'
            //         },
            //         {
            //             label: 'Consultants',
            //             // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
            //             command: () => {
            //                 this.props.history.push('/consultants')
            //             },
            //             //  isPermRequired: true,
            //             //   uId: 'ffd94731-f615-4101-828a-70bbd6022651'
            //             // icon: 'pi pi-fw pi-align-left'
            //         }
            //     ]

            // },

        ];

        let assignedM = [];
        // if (this.props.authenticated) {
        //     assignedM = ModuleMenuItems
        // }


        if (this.props?.user?.user?.isAdmin) {
            return ModuleMenuItems;
        }

        if (this.props.permissionIds && this.props.permissionIds.length) {


            assignedM = ModuleMenuItems.filter(item => {

                if (item.isPermRequired) {
                    // 
                    if (this.props.permissionIds.includes(item.uId)) {
                        if (item.items) {
                            item.items = item.items.filter(subItem => {
                                if (subItem.isPermRequired) {
                                    //
                                    if (subItem.items && subItem.items.length) {
                                        subItem.items = subItem.items.filter(subItem2 => {
                                            if (subItem2.isPermRequired) {
                                                if (this.props.permissionIds.includes(subItem2.uId)) {
                                                    return subItem2;
                                                }
                                            } else {
                                                return subItem2;
                                            }
                                        });
                                    }
                                    return subItem;
                                } else {
                                    if (subItem.items && subItem.items.length) {
                                        subItem.items = subItem.items.filter(subItem2 => {
                                            if (subItem2.isPermRequired) {
                                                if (this.props.permissionIds.includes(subItem2.uId)) {
                                                    return subItem2;
                                                }
                                            } else {
                                                return subItem2;
                                            }
                                        });

                                    }
                                    return subItem;
                                }
                            });
                        }

                        if (item && item.items && item.items.length) {
                            return item
                        }
                    }
                } else {
                    if (item.items) {
                        item.items = item.items.filter(subItem => {
                            if (subItem.isPermRequired) {
                                if (this.props.permissionIds.includes(subItem.uId)) {
                                    if (subItem.items && subItem.items.length) {
                                        subItem.items = subItem.items.filter(subItem2 => {
                                            if (subItem2.isPermRequired) {
                                                if (this.props.permissionIds.includes(subItem2.uId)) {
                                                    return subItem2;
                                                }
                                            } else {
                                                return subItem2;
                                            }
                                        });
                                    }
                                    return subItem;
                                }
                            } else {
                                if (subItem.items && subItem.items.length) {
                                    subItem.items = subItem.items.filter(subItem2 => {
                                        if (subItem2.isPermRequired) {
                                            if (this.props.permissionIds.includes(subItem2.uId)) {
                                                return subItem2;
                                            }
                                        } else {
                                            return subItem2;
                                        }
                                    });

                                }
                                return subItem;
                            }
                        });
                    } else {
                        return item;
                    }
                    if (item && item.items && item.items.length) {
                        return item
                    }
                }
            });
        }


        assignedM = assignedM.filter(i => {
            if (i && i.items) {
                if (i.items.length) {
                    i.items = i.items.filter(j => {
                        if (j && j.items) {
                            if (j.items.length) {
                                j.items = j.items.filter(k => {
                                    if (k && k.items) {
                                        if (k.items.length) {
                                            return k;
                                        }
                                    } else {
                                        if (k.command) {
                                            return k;
                                        }

                                    }
                                })
                                if (j.items.length) {
                                    return j;
                                }

                            }
                        } else {
                            if (j.command) {
                                return j;
                            }

                        }
                    })

                    if (i.items.length) {
                        return i;
                    }
                }
            } else {
                if (i.command) {
                    return i;
                }

            }
        })


        //  console.log(assignedM)

        return assignedM;


    }

    changeTheme = (selectedTheme) => {

        const themes = this.state.themes.map((theme) => {
            if (theme.value == selectedTheme.value) {
                theme.isSelected = true;
            } else {
                theme.isSelected = false;

            }
            return theme;
        })
        this.setState({
            themes
        });



        localStorage.setItem('themes', JSON.stringify(themes));
        localStorage.setItem('data-theme', selectedTheme.value);
        document.documentElement.setAttribute('data-theme', localStorage.getItem('data-theme'));
        //  window.location.reload();
    }


    getCredits = () => {
        this.setState({
            isLoading: true,
        });
        const url = `/admin/get-credits`;
        this.serverlessService.get(url, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    credits: res.res.data
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Templates fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    }

    componentDidUpdate(prevProps) {
        if (prevProps.user != this.props.user) {
            this.getCredits();
        }
    }

    componentDidMount() {

        localStorage.setItem('data-theme', 'lightblue');
        document.documentElement.setAttribute('data-theme', 'lightblue');

        // socket.on("smsLimitUpdated", (data) => {
        //     console.log(data)

        //     this.setState({
        //         total: data.smsLimit
        //     })
        // });

        this.getCredits()
    }

    getThemes = () => {
        const { themes } = this.state;
        return <ul className='sp-p10'>{themes.map(theme => {
            return <li key={theme.name} className='sp-m10' >
                <RadioButton className="radio-inline" value={theme.value} inputId={theme.value} name="theme"
                    onChange={() => {
                        this.changeTheme(theme)
                    }}
                    checked={theme.isSelected}
                />
                <label className='sp-label-s1' htmlFor={theme.value}>{theme.name}</label>
            </li>
        })}  </ul>


    }



    render() {

        const items = [
            // {
            //     label: 'Theme',
            //     icon: 'pi pi-slack',
            //     items: [
            //         {
            //             template: <>{this.getThemes()}</>
            //         }
            //     ]
            // },

            // {
            //     separator: true
            // },
            {
                label: 'Log Out',
                icon: 'pi pi-fw pi-power-off',
                command: () => { this.onLogout() }
            }
        ];

        // console.log(this.props.user, 'user')
        return <div>
            <div className="sp-menubar-w">

                <div className="p-grid sp-menubar sp-no-pm">
                    <div className="p-col-12 p-md-2 sp-no-p">
                        <a className='app-title' onClick={() => { this.props.history.push('/') }} >
                            <img src="./images/logoofficial.svg" alt="dd"
                                class="off-logo" />
                            <span>Sendshortly</span>
                        </a>
                    </div>
                    <div className="p-col-12 p-md-6 sp-no-p">
                        <Menubar model={this.getNavbarModules()}
                            className="m-menu-bar "
                        />
                    </div>

                    {
                        this.props.authenticated ? <>
                            <div className="p-col-12 p-md-4 sp-right ">
                                <div style={{ width: '40px', float: 'right' }}>
                                    <img src={this.props?.user?.user?.photo} style={{ height: '34px', borderRadius: '50%' }} />
                                </div>
                                <div style={{ marginRight: '50px' }} onClick={(event) => this.menu.toggle(event)}>
                                    <p style={{ textAlign: 'right' }} className='sp-profile'>
                                        {/* <i className='pi pi-angle-down'></i> */}
                                        <span>SMS Credits: {this.props?.user?.user?.smsLimit} </span>
                                        <span>Email Credits:  {this.props?.user?.user?.emailLimit} </span>
                                        {this.props?.user?.user?.orgName} - {this.props?.user?.user?.name}</p>
                                </div>
                            </div>
                        </> : <>
                            <div className="p-col-12 p-md-4 sp-right ">
                                <p className='sp-right login-txt' onClick={() => { this.props.history.push('/login') }}>Login / Register</p>
                            </div>

                        </>
                    }
                </div>

                <TieredMenu model={items} popup ref={el => this.menu = el} />

                {
                    this.props.isLoading && <LoadingComponent />
                }



            </div>
            <div className='body-container'>
                {this.props.children}
            </div>
            <footer className='footer'>
                <p className='footer-p'>&copy; SendShortly 2023</p>
                <p className='all-rights'>All rights reserved.</p>
            </footer>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </div>
    }

}


const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    permissionIds: state?.user?.user?.permissionIds || []
});


export default connect(mapStateToProps, {
})(Authentication(withRouter(Header)));

