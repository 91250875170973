import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import { Button } from 'primereact/button';
import { trimObj } from 'src/Utils';
import doctorRegisterFormFields from './patientRegisterForm.json';
import Service from 'src/Service';
import { saveUserSession } from './../store/actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../session/index'
import LoadingComponent from 'src/LoadingComponent';
import { InputTextarea } from 'primereact/inputtextarea';
import VerifyOTP from './verifyOTP';
import ServerlessService from 'src/Service/ServerlessService';
import { ORG_CODE } from 'src/Service/config';




class PatientRegistration extends React.Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(doctorRegisterFormFields, {});
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isNewUser: false,
            isShowVerifyOtp: false
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }



    isAdditionalValid = () => {

        let user = JSON.parse(JSON.stringify(this.state.user));
        let { formValidations } = this.state;
        // password and confirm password 
        let isValid = true;
        if (user.password != user.passwordConfirm) {
            isValid = false;
            formValidations.fields['passwordConfirm'] = { ...formValidations.fields['passwordConfirm'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        this.setState((prevState) => {
            return {
                formValidations
            };
        });

        return isValid

    }


    onRegisterSuccess = (user) => {



    }


    onRegister = () => {


        const formStatus = isFormValid(doctorRegisterFormFields, this.formFields.formValidations, trimObj(this.state.user));


        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }


        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }


        if (isCompleteFormValid) {
            let data = trimObj(this.state.user);


            this.setState({
                isLoading: true
            });
            const url = `/admin/sign-up`;
            data.orgCode = ORG_CODE;

            this.service.post(url, data, true).then((res) => {



                if (res && res.status && res.res.status) {

                    if (res.res.statuscode == 2) {
                        this.setState({
                            userId: res.res.user,
                            isLoading: false,
                            isShowVerifyOtp: true
                        });
                    } else {

                        // this.setState({
                        //     isLoading: false
                        // }, () => {
                        //     this.props.saveUserSession(res.res);
                        //     this.props.history.push('/')
                        // })
                    }



                } else {

                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })



        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authenticated != this.props.authenticated && this.props.authenticated) {
            this.props.history.push('/')
        }
    }




    render() {
        // console.log(this.props)
        const { user, formValidations, isNewUser, isShowVerifyOtp, userId } = this.state;
        return (<>
            <div className='doctor-login-form'>
                <div>

                    {isShowVerifyOtp ? <>
                        <VerifyOTP userId={userId} />
                    </> : <>
                        <div>
                            <p className='sp-label-s1 sp-mt10 sp-no-pm'>Organization Name<span className='sp-required'>*</span></p>
                            <InputText className='sp-w100p p-inputtext-style1'
                                autoComplete="off" value={user.orgName}
                                onChange={(e) => { onTextChange(e.target.value, 'orgName', this, doctorRegisterFormFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['orgName'].isValid && <p className="p-error">{formValidations.fields['orgName'].errorMsg}</p>}
                            <p className='sp-label-s1 sp-mt10 sp-no-pm'>Email<span className='sp-required'>*</span></p>
                            <InputText value={user.email}
                                className='sp-w100p  p-inputtext-style1'
                                onChange={(e) => { onEmailChange(e.target.value, 'email', this, doctorRegisterFormFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}
                            <p className='sp-label-s1 sp-mt10 sp-no-pm'>Password<span className='sp-required'>*</span></p>
                            <InputText value={user.password}
                                type="password"
                                autoComplete="new-password"
                                className='sp-w100p  p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'password', this, doctorRegisterFormFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['password'].isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                            <p className='sp-label-s1 sp-mt10 sp-no-pm'>Confirm Password<span className='sp-required'>*</span></p>
                            <InputText value={user.passwordConfirm}
                                type="password"
                                autoComplete="new-password"
                                className='sp-w100p  p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'passwordConfirm', this, doctorRegisterFormFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['passwordConfirm'].isValid && <p className="p-error">{formValidations.fields['passwordConfirm'].errorMsg}</p>}
                            <p className='sp-label-s1 sp-mt10 sp-no-pm'>Your name<span className='sp-required'>*</span></p>
                            <InputText value={user.name}
                                autoComplete="new-password"
                                className='sp-w100p  p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'name', this, doctorRegisterFormFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                        </div>
                        <Toolbar className="sp-toolbar sp-mt20"
                            right={() => {
                                return (<Button label='Register' className=''
                                    onClick={this.onRegister}
                                />)
                            }}
                            left={() => {
                                return (<>
                                    <p>Are you registed? <span className='register-txt' onClick={this.props.onLoginClick} >Login</span></p>
                                </>)
                            }}

                        ></Toolbar>
                    </>}
                </div>
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }

        </>)
    }
}


const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
});


export default connect(mapStateToProps, {
    saveUserSession,
})(Authentication(withRouter(PatientRegistration)));
