import { BrowserRouter, Route } from 'react-router-dom';
import { Login } from './Login';
//import Appointments from './Appointments';
import 'primeflex/primeflex.css';
// import './theme.scss';
import './fonts/fonts.css';
import './App.scss'
import HomePage from './HomePage';
import EmailEditorComponent from './EmailEditor';
import EmailSignature from './EmailSignature';
import EmailTemplate from './EmailTemplate';
import EmailUsers from './EmailUsers';
import BulkEmails from './BulkEmails';
import BulkSms from './components/sms/bulkSms';
import BulkEmailUsersUpload from './EmailUsers/bulkUpload';
import EmailConfig from './EmailConfig';
import AdminUser from './AdminManagement/AdminUser';
import Roles from './AdminManagement/Roles';
import ComposeEmail from './BulkEmails/composeEmail';
import PeManagement from './components/sms/peManagement';
import SMSHeader from './components/sms/senderIds';
import SMSTemlates from './components/sms/templates';
import ComposeSms from './components/sms/bulkSms/composeSms';
import io from 'socket.io-client';
import { EMAIL_SERVER_URL } from './Service/config';
import APIDocumentation from './components/sms/apiDocumentation';
import smsReports from './components/sms/smsReports';
import URLShortener from './components/sms/urlShortener'
import PeIds from './components/sms/peIds';
import BulkVoice from './BulkVoice';
import ConsultantForms from './Consultant/ConsultantForms';

const ENDPOINT = EMAIL_SERVER_URL;
// export const socket = io(ENDPOINT);

localStorage.setItem('data-theme', 'lightblue');
document.documentElement.setAttribute('data-theme', 'lightblue');


export const App = () => {
  return (
    <div>
      <BrowserRouter>
        <div className="App">
          <Route exact path='/' component={HomePage} />
          <Route exact path='/login' component={Login} />

          {/* <Route exact path='/appointments' component={Appointments} /> */}
          {/* <Route exact path='/appointment-book' component={BookAnAppointment} /> */}
          <Route exact path='/email' component={EmailEditorComponent} />
          <Route exact path='/signature' component={EmailSignature} />
          <Route exact path='/template' component={EmailTemplate} />
          <Route exact path='/emailusers' component={EmailUsers} />
          <Route exact path='/bulk-emailusers' component={BulkEmailUsersUpload} />
          <Route exact path='/bulk-emails' component={BulkEmails} />
          <Route exact path='/compose' component={ComposeEmail} />
          <Route exact path='/config' component={EmailConfig} />
          <Route exact path='/admin' component={AdminUser} />
          <Route exact path='/roles' component={Roles} />
          {/* <Route exact path='/pe-management' component={PeManagement} /> */}
          <Route exact path='/principal-entities' component={PeIds} />
          <Route exact path='/sms-senderids' component={SMSHeader} />
          <Route exact path='/sms-templates' component={SMSTemlates} />
          <Route exact path='/sms-reports' component={smsReports} />
          <Route exact path='/api-documentation' component={PeManagement} />
          <Route exact path='/sms-compaign' component={PeManagement} />
          <Route exact path='/send-sms' component={ComposeSms} />
          <Route exact path='/bulk-sms' component={BulkSms} />
          <Route exact path='/api-doc' component={APIDocumentation} />
          <Route exact path='/url-shortener' component={URLShortener} />

          <Route exact path='/bulk-voice' component={BulkVoice} />

          <Route exact path='/consultant-forms' component={ConsultantForms} />



          {/* <Route exact path='/appointmentSuccess' component={AppointmentSuccess} />
          <Route exact path='/appointmentCancel' component={AppointmentCancel} />
          <Route exact path='/appointmentView' component={ViewAppointments} />
          <Route exact path='/docAppointmentCancel' component={DocAppointmentCancel} />
          <Route exact path='/patAppointmentView' component={ViewPatientAppointments} />
          <Route exact path='/feedback' component={Feedback} />
          <Route exact path='/feedbackSuccess' component={FeedbackSuccess} /> */}
        </div>
      </BrowserRouter>
    </div>
  );
}

