import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Service from 'src/Service';
import cloneDeep from 'lodash/cloneDeep';
import { Dialog } from 'primereact/dialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../../session/index'
import { BasicLazyParams } from 'src/Utils/constants';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';
import { getTimeSlotByKey, warningDailogInit } from 'src/Utils';
import { Specialization } from 'src/Utils/constants';
// import AppointmentInfo from './AppointmentInfo';
import CreateAdminUser from './createAdminUser';
import Header from '../../Header';
import Authorize, { PERMISSIONS } from 'src/session/authorize';
import { Toolbar } from 'primereact/toolbar';
import AssignEmail from './assignEmail';
import ServerlessService from 'src/Service/ServerlessService';
import LoadingComponent from 'src/LoadingComponent';
import CreditManagement from './creditManagement';

class AdminUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAddAdminUser: false,
            appointments: [],
            lazyParams: { ...BasicLazyParams, sortField: '-createdAt', sortOrder: 1 },
            globalSearch: '',
            isShowAdminUser: false,
            appointment: null,
            currentFilter: 1,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            assignedMails: [],
            isShowAssignNewEmail: false,
            removeAssignEmail: null
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    showBookAnAppointment = () => {
        this.setState({ isShowAddAdminUser: true, editAdminUser: false });
        // this.props.history.push('/appointment-book')

    }


    onChangeStatusType = (currentFilter) => {
        this.setState((prevState) => {
            return {

                currentFilter: currentFilter,
                lazyParams: BasicLazyParams
            }
        }, this.getAppointments);
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getAppointments);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getAppointments);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getAppointments);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getAppointments);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }



    getAppointments = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            appointments: []
        });
        const url = `/admin/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.post(url, { status: this.state.currentFilter }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    appointments: res.res.data,
                    totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'AdminUsers fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }


    editAdminUser = (adminUser) => {
        this.setState({
            isShowAddAdminUser: true,
            editAdminUser: adminUser
        })
    }

    deleteAdminUser = (rowData) => {
        let data = { ...rowData }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You want delete the adminUser, it can't be undone once deleted`
            },
            editAdminUser: data
        });

    }


    getUserAssignedMails = (userId) => {

        this.setState({
            isLoading: true,
            assignedMails: []
        });
        const url = `/admin/assigned-mails/${userId}`;
        this.serverlessService.get(url, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    assignedMails: res.res.data,
                    totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'AdminUsers fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }




    assignEmail = (editAdminUser) => {

        this.setState({
            isShowAssignEmail: true,
            editAdminUser: editAdminUser
        }, () => {
            this.getUserAssignedMails(editAdminUser._id)
        });

    }





    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });

        const url = `/admin/delete`;
        this.serverlessService.post(url, { _id: this.state.editAdminUser._id }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    editAdminUser: null,
                    isLoading: false
                }, () => {
                    this.getAppointments();
                })


                this.toast.show({ severity: 'success', summary: 'Success', detail: 'AdminUsers deleted successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    showCreditManagement = (editRow) => {

        this.setState({
            editAdminUser: editRow,
            isShowCreditsAdd: true
        });


    }

    onCreditsAdd = () => {
        this.toast.show({ severity: 'success', summary: `Credits added`, detail: `Credits added succesfully.`, life: 3000 });
        this.setState({
            isShowCreditsAdd: false,
            lazyParams: BasicLazyParams
        }, () => {
            this.getAppointments();
        })

    }

    actionsTemplate = (rowData) => {
        return (<>
            <div className="p-d-flex sp-mlr10 user-edit-t">
                {/* <span data-pr-tooltip="Assign Emails" data-pr-position="bottom" className={`sp-ml10 anal${rowData._id.substr(2)}`} onClick={() => this.assignEmail(rowData)} >
                    <i className='pi pi-directions' />
                </span>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.anal${rowData._id.substr(2)}`} />
 */}
                {
                    !rowData.isAdmin ? <Authorize permId={PERMISSIONS.ADMIN_USER_EDIT} >
                        <span data-pr-tooltip="Edit AdminUser" data-pr-position="bottom" className={`sp-ml10 edit${rowData._id.substr(2)}`} onClick={() => { this.editAdminUser(rowData) }}>
                            <i className='pi pi-pencil' />
                        </span>
                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id.substr(2)}`} />
                    </Authorize> : <span style={{ width: '26px' }}></span>
                }


                {/* <Authorize permId={PERMISSIONS.ADMIN_USER_CREDITS_ADD} > */}
                <span data-pr-tooltip="Add Credit" data-pr-position="bottom" className={`sp-ml10 cr${rowData._id.substr(2)}`} onClick={() => { this.showCreditManagement(rowData) }}>
                    <i className='pi pi-wallet' />
                </span>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.cr${rowData._id.substr(2)}`} />
                {/* </Authorize>

                <Authorize permId={PERMISSIONS.ADMIN_USER_WATCH_EMAIL_STATS} > */}
                {/* <span data-pr-tooltip="See Email Campaign Reports" data-pr-position="bottom" className={`sp-ml10 cr${rowData._id.substr(2)}`} onClick={() => { this.showCreditManagement(rowData) }}>
                    <i className='pi pi-chart-bar' />
                </span>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.cr${rowData._id.substr(2)}`} /> */}
                {/* </Authorize> */}



                {/* <Authorize permId={PERMISSIONS.ADMIN_USER_DELETE} >
                    <span data-pr-tooltip="Delete User" data-pr-position="bottom" className={`sp-ml10 del${rowData._id.substr(2)}`} onClick={() => { this.deleteAdminUser(rowData) }}>
                        <i className='pi pi-trash' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.del${rowData._id.substr(2)}`} />
                </Authorize> */}
            </div>
        </>
        );
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    onSaveAdminUser = (isEdit) => {
        this.toast.show({ severity: 'success', summary: `${isEdit ? 'Added' : 'Modified'}`, detail: `AdminUser ${isEdit ? 'Edited' : 'Created'} Succesfully.`, life: 3000 });


        this.setState({
            isShowAddAdminUser: false,
            lazyParams: BasicLazyParams
        }, () => {
            this.getAppointments();
        })

    }


    componentDidMount() {


        this.getAppointments();
    }

    showAssignNewEmail = () => {
        this.setState({
            isShowAssignNewEmail: true
        });
    }

    removeassign = (rowData) => {

        this.setState({
            warningDialog2: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You want remove assigned  ${rowData.email} email. Please confirm`
            },
            removeAssignEmail: rowData
        });

    }

    onConfirmChange2 = () => {
        this.setState({
            isLoading: true,
            warningDialog2: warningDailogInit,
        });
        const url = `/admin/removeAssign-mail`;
        this.serverlessService.post(url, { userId: this.state.editAdminUser._id, emailId: this.state.removeAssignEmail._id }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                }, this.getUserAssignedMails(this.state.editAdminUser._id))

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'AdminUsers fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }

    render() {
        const { currentFilter } = this.state;

        // console.log(this.state.appointments)
        const header = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >All Users</h2>
                    {/* 
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 1 ? 'p-button-outlined' : ''}`} label={'Booked'} onClick={() => this.onChangeStatusType(1)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 2 ? 'p-button-outlined' : ''}`} label={'Visisted'} onClick={() => this.onChangeStatusType(2)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 3 ? 'p-button-outlined' : ''}`} label={'Cancelled'} onClick={() => this.onChangeStatusType(3)} /> */}

                    {this.state.showSearch && <InputText placeholder="Search" className="sp-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />}
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        <Authorize permId={PERMISSIONS.ADMIN_USER_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search sp-tbl-icon sp-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".sp-tbl-srch" />
                        </Authorize>
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );


        const header2 = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >Emails Assigned</h2>
                    {/* 
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 1 ? 'p-button-outlined' : ''}`} label={'Booked'} onClick={() => this.onChangeStatusType(1)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 2 ? 'p-button-outlined' : ''}`} label={'Visisted'} onClick={() => this.onChangeStatusType(2)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 3 ? 'p-button-outlined' : ''}`} label={'Cancelled'} onClick={() => this.onChangeStatusType(3)} /> */}

                    {/* {this.state.showSearch && <InputText placeholder="Search" className="sp-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />} */}
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        {/* <Authorize permId={PERMISSIONS.ADMIN_USER_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search sp-tbl-icon sp-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".sp-tbl-srch" />
                        </Authorize> */}
                        {/* <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" /> */}
                    </ul>
                </div>
            </div>
        );

        return (<div>
            <Header>
                <div className='sp-main'>
                    <Authorize permId={PERMISSIONS.ADMIN_USER_ADD} >
                        <Button label="+ Add User" className='sp-m-lr10'
                            onClick={this.showBookAnAppointment} />
                    </Authorize>
                    {
                        this.state.isShowAddAdminUser && <CreateAdminUser
                            adminUser={this.state.editAdminUser} onHide={() => {

                                this.setState({
                                    isShowAddAdminUser: false
                                })
                            }}

                            onSave={this.onSaveAdminUser}
                        />
                    }
                    <Authorize permId={PERMISSIONS.ADMIN_USER_LIST} >
                        <div className="card datatable-crud-demo sp-m30 fetch-q-tabled">
                            {/* <LazyTable ></LazyTable> */}
                            <DataTable ref={(el) => this.dt = el}
                                //  lazy
                                scrollable
                                responsiveLayout="scroll"
                                //  autoLayout={true}
                                rowHover
                                value={this.state.appointments}
                                // selection={this.state.selectedProducts}
                                // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                dataKey="_id"
                                //</div>={true}
                                lazy
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                paginator
                                rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                                header={header}

                            >
                                <Column field="name" header="Name" />
                                <Column field="email" header="Email" />
                                <Column field="emailLimit" header="Email Credits" />
                                <Column
                                    // headerClassName='option-2'
                                    //headerStyle={{ width: "520px",  }}
                                    //headerStyle={{ display: 'inline-block   ' }}
                                    sortabl body={this.actionsTemplate} header="Actions" ></Column>
                            </DataTable>

                        </div>
                    </Authorize>


                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </div>

                <Dialog
                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='sp-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editAdminUser: null }) }} />
                            <Button label="Yes" className='sp-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editAdminUser: null })}>
                    <div>
                    </div>
                    <p className='sp-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='sp-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>
                <Dialog

                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='sp-alert-box'
                    dismissableMask={true}
                    closable={true}
                    visible={this.state.isShowAssignEmail}
                    style={{ width: '90%', height: '90vh' }}

                    // footer={() => {
                    //     return (<div>
                    //         <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editAdminUser: null }) }} />
                    //         <Button label="Yes" className='sp-m-lr10' onClick={this.onConfirmChange} />

                    //     </div>)
                    // }}
                    onHide={() => this.setState({ isShowAssignEmail: false, editAdminUser: null })}>
                    <div>
                        <div className="card datatable-crud-demo sp-m30 fetch-q-tabled">
                            {/* <LazyTable ></LazyTable> */}
                            <DataTable ref={(el) => this.dt = el}
                                //  lazy
                                scrollable
                                responsiveLayout="scroll"
                                //  autoLayout={true}
                                rowHover
                                value={this.state.assignedMails}
                                // selection={this.state.selectedProducts}
                                // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                dataKey="_id"
                                //</div>={true}
                                // lazy
                                // onSort={this.onSort}
                                // onPage={this.onPage}
                                // onFilter={this.onFilter}
                                // first={this.state.lazyParams.first}
                                // last={this.state.totalRecords}
                                // rows={this.state.lazyParams.rows}
                                totalRecords={this.state.assignedMails.length}
                                paginator
                                rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                // globalFilter={this.state.globalFilter}
                                header={header2}

                            >
                                <Column field="email" header="Email" />

                                <Column
                                    // headerClassName='option-2'
                                    //headerStyle={{ width: "520px",  }}
                                    //headerStyle={{ display: 'inline-block   ' }}
                                    body={(rowData) => {
                                        return (<>
                                            <div className="p-d-flex sp-mlr10 user-edit-t">

                                                {/* <Authorize permId={PERMISSIONS.CONFIGURATIONS_EDIT} > */}
                                                <span data-pr-tooltip="Assign" data-pr-position="bottom" className={`sp-ml10 edit${rowData._id.substr(2)}`} onClick={() => { this.removeassign(rowData) }}>
                                                    <i className='pi pi-times' />
                                                </span>
                                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id.substr(2)}`} />
                                                {/* </Authorize> */}

                                            </div>
                                        </>
                                        );
                                    }}
                                    header="Actions" ></Column>
                            </DataTable>

                        </div>

                        {

                        }



                        {
                            this.state.isShowAssignNewEmail ? <AssignEmail onCancel={() => {
                                this.setState({
                                    isShowAssignNewEmail: false
                                })
                            }}
                                editAdminUser={this.state.editAdminUser}
                                onNewlyAssign={() => { this.getUserAssignedMails(this.state.editAdminUser._id) }}
                            />
                                : <Button label='Assign Email' className='sp-mt10'
                                    onClick={this.showAssignNewEmail}
                                />}
                    </div>

                </Dialog>
                <Dialog
                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='sp-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog2.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog2: warningDailogInit, removeAssignEmail: null }) }} />
                            <Button label="Yes" className='sp-m-lr10' onClick={this.onConfirmChange2} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog2: warningDailogInit, editAdminUser: null })}>
                    <div>
                    </div>
                    <p className='sp-alert-head'>{this.state.warningDialog2.headerMsg}</p>
                    <p className='sp-alert-msg'>{this.state.warningDialog2.message}</p>
                </Dialog>

                {
                    this.state.isLoading && <LoadingComponent />
                }
                {
                    this.state.isShowCreditsAdd && <CreditManagement

                        editAdminUser={this.state.editAdminUser}
                        onCreditsAdd={this.onCreditsAdd}
                        onHide={
                            () => {
                                this.setState({
                                    isShowCreditsAdd: false,
                                    editSeller: null
                                })
                            }
                        }

                    />
                }
            </Header>
        </div>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(AdminUser));
