import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from 'src/Service';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import signatureFields from './signature.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { trimObj } from 'src/Utils';
import ServerlessService from 'src/Service/ServerlessService';
import VariableAddPopup from 'src/EmailTemplate/variableAddPopup';

class CreateSignature extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(signatureFields, this.props.signature ? this.props.signature : {});
        this.state = {
            signature: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    saveSignature = () => {
        let signatureData = trimObj(this.state.signature)
        const formStatus = isFormValid(signatureFields, this.formFields.formValidations, signatureData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            let url = `/signature/save`
            if (this.props.signature) {
                url = `/signature/update`
            }


            this.setState({
                isLoading: true,
            });

            this.serverlessService.post(url, signatureData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.signature ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.signature ? 'Update' : 'Save'} Signature`} className='sp-m-lr10'
                onClick={this.saveSignature}
            />
        </div>)
    }

    addVariable = (variable) => {
        this.setState((prevState) => ({
            signature: {
                ...prevState.signature,
                signature: `${prevState.signature.signature} {{${variable}}}`
            }
        }));
    }



    render() {
        const { signature, formValidations } = this.state;

        //   console.log(this.state, 'state-appointment')
        return (<>
            <Dialog header={`${this.props.signature ? 'Edit' : 'Create'} Signature`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '90%', height: '90vh' }}
                onHide={this.props.onHide}>

                <div>
                    <div className="p-grid sp-mt20">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Signature Name<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={signature.signatureName}
                                autoComplete="new-password"
                                className='sp-w200 p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'signatureName', this, signatureFields, signature, formValidations, 'signature', 'formValidations') }} />
                            {formValidations && !formValidations.fields['signatureName'].isValid && <p className="p-error">{formValidations.fields['signatureName'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Signature<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <VariableAddPopup addVariable={this.addVariable} />

                            <ClassicEditor
                                data={signature.signature}
                                onChange={(value) => { onTextChange(value, 'signature', this, signatureFields, signature, formValidations, 'signature', 'formValidations') }}
                            />

                            {formValidations && !formValidations.fields['signature'].isValid && <p className="p-error">{formValidations.fields['signature'].errorMsg}</p>}
                        </div>
                    </div>
                    {/* <div dangerouslySetInnerHTML={{ __html: signature.signature }}></div> */}

                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateSignature)));
